import type { HeadersFunction, LoaderFunctionArgs, MetaFunction } from '@remix-run/node'
import { Link } from '@remix-run/react'

import insightsDarkmode from '@/assets/graphs/insights-p50-p95-p99-darkmode.png'
import insights from '@/assets/graphs/insights-p50-p95-p99.png'
import social from '@/assets/social.png?url'
import Logo from '@/components/Logo'
import SiteFooter from '@/components/SiteFooter'
import SiteHeader from '@/components/SiteHeader'
import VitessDiagram from '@/components/VitessDiagram'
import { cacheControl } from '@/http.server'
import { pageHelper } from '@/request'

type LoaderData = typeof loader

export async function loader({ request }: LoaderFunctionArgs) {
  const { root } = pageHelper(request)

  return { root: root }
}

export const headers: HeadersFunction = () => ({
  'Cache-Control': cacheControl
})

export const meta: MetaFunction<LoaderData> = ({ data }) => {
  if (!data) return []

  const { root } = data

  const title = 'PlanetScale - the world’s fastest and most reliable OLTP database'
  const description = 'PlanetScale is the world’s fastest and most reliable OLTP database.'
  const image = `${root}${social}`

  return [
    { title },
    { name: 'description', content: description },
    { property: 'og:url', content: 'https://planetscale.com' },
    { property: 'og:type', content: 'website' },
    { property: 'og:title', content: title },
    { property: 'og:image', content: image },
    { property: 'og:description', content: description },
    { property: 'twitter:card', content: 'summary_large_image' },
    { property: 'twitter:site', content: '@PlanetScale' },
    { property: 'twitter:creator', content: '@PlanetScale' },
    { property: 'twitter:url', content: 'https://planetscale.com' },
    { property: 'twitter:title', content: title },
    { property: 'twitter:description', content: description },
    { property: 'twitter:image', content: image },
    { tagName: 'link', rel: 'canonical', href: 'https://planetscale.com' }
  ]
}

export default function Homepage() {
  return (
    <>
      <SiteHeader />

      <main className='container my-6 max-w-7xl px-3 sm:px-5 lg:px-12'>
        <section>
          <div className='flex flex-col gap-y-3'>
            <div>
              <h1>The world’s fastest and most reliable OLTP database</h1>

              <p>
                Backed by Vitess, our cloud platform makes achieving a shared-nothing architecture with explicit
                sharding simpler than ever. PlanetScale’s platform delivers horizontal scale, performance, and
                simplified operations no matter the size your business.
              </p>

              <p>
                <Link prefetch='intent' to='/metal'>
                  PlanetScale Metal
                </Link>
                ’s blazing fast NVMe drives unlock <strong>unlimited IOPS</strong>, bringing data center performance to
                the cloud. We offer a range of{' '}
                <Link prefetch='intent' to='/docs/concepts/deployment-options'>
                  deployment options
                </Link>{' '}
                to cover all of your security and compliance requirements &mdash; including bring your own cloud with{' '}
                <Link prefetch='intent' to='/docs/enterprise/managed'>
                  PlanetScale Managed
                </Link>
                .
              </p>

              <div className='flex flex-col space-y-1 md:flex-row md:space-y-0'>
                <ul className='my-0 ml-2 w-1/4 space-y-1'>
                  <li>
                    <Link to='#performance'>Performance</Link>
                  </li>
                  <li>
                    <Link to='#vitess'>Vitess</Link>
                  </li>
                  <li>
                    <Link to='#uptime'>Uptime</Link>
                  </li>
                </ul>

                <ul className='my-0 ml-2 w-1/4 space-y-1'>
                  <li>
                    <Link to='#cost'>Cost</Link>
                  </li>
                  <li>
                    <Link to='#security'>Security</Link>
                  </li>
                  <li>
                    <Link to='#platform'>Features</Link>
                  </li>
                </ul>
              </div>
            </div>

            <div>
              <p>
                Our technology powers{' '}
                <Link prefetch='intent' to='/case-studies/cash-app'>
                  Tier 0 databases
                </Link>{' '}
                at:
              </p>

              <div className='mb-3 grid grid-cols-2 grid-rows-5 items-center justify-center md:grid-cols-5 md:grid-rows-2'>
                <Link
                  to='/case-studies/cash-app'
                  prefetch='intent'
                  className='group relative -m-[0.5px] flex h-16 items-center justify-center border transition hover:bg-gray-100/50 md:h-22 dark:hover:bg-black'
                >
                  <Logo name='block' ariaLabel='Block' className='max-h-12 w-3/4 max-w-20 dark:hidden' />
                  <Logo name='block-darkmode' ariaLabel='Block' className='hidden max-h-12 w-3/4 max-w-20 dark:block' />
                  <span className='absolute bottom-sm text-secondary opacity-0 transition group-hover:opacity-100'>
                    Read case study
                  </span>
                </Link>

                <div className='-m-[0.5px] flex h-16 items-center justify-center border md:h-22'>
                  <Logo name='etsy' ariaLabel='Etsy' className='max-h-12 w-3/4 max-w-20' />
                </div>

                <a
                  href='https://pscale.link/int'
                  rel='nofollow noopener noreferrer'
                  target='_blank'
                  className='group relative -m-[0.5px] flex h-16 items-center justify-center border transition hover:bg-gray-100/50 md:h-22 dark:hover:bg-black'
                >
                  <Logo name='intercom' ariaLabel='Intercom' className='max-h-12 w-3/4 max-w-20 dark:hidden' />
                  <Logo
                    name='intercom-darkmode'
                    ariaLabel='Intercom'
                    className='hidden max-h-12 w-3/4 max-w-20 dark:block'
                  />
                  <span className='absolute bottom-sm text-secondary opacity-0 transition group-hover:opacity-100'>
                    Read case study
                  </span>
                </a>

                <div className='-m-[0.5px] flex h-16 items-center justify-center border md:h-22'>
                  <Logo name='gusto' ariaLabel='Gusto' className='max-h-12 w-3/4 max-w-20' />
                </div>

                <div className='-m-[0.5px] flex h-16 items-center justify-center border md:h-22'>
                  <Logo name='kick' ariaLabel='Kick' className='max-h-12 w-3/5 max-w-20' />
                </div>

                <Link
                  to='/case-studies/myfitnesspal'
                  prefetch='intent'
                  className='group relative -m-[0.5px] flex h-16 items-center justify-center border transition hover:bg-gray-100/50 md:h-22 dark:hover:bg-black'
                >
                  <Logo name='myfitnesspal' ariaLabel='MyFitnessPal' className='max-h-12 w-3/4 max-w-20 dark:hidden' />
                  <Logo
                    name='myfitnesspal-darkmode'
                    ariaLabel='MyFitnessPal'
                    className='hidden max-h-12 w-3/4 max-w-20 dark:block'
                  />
                  <span className='absolute bottom-sm text-secondary opacity-0 transition group-hover:opacity-100'>
                    Read case study
                  </span>
                </Link>

                <Link
                  to='/case-studies/attentive'
                  prefetch='intent'
                  className='group relative -m-[0.5px] flex h-16 items-center justify-center border transition hover:bg-gray-100/50 md:h-22 dark:hover:bg-black'
                >
                  <Logo name='attentive' ariaLabel='Attentive' className='max-h-12 w-3/4 max-w-20 dark:hidden' />
                  <Logo
                    name='attentive-darkmode'
                    ariaLabel='Attentive'
                    className='hidden max-h-12 w-3/4 max-w-20 dark:block'
                  />
                  <span className='absolute bottom-sm text-secondary opacity-0 transition group-hover:opacity-100'>
                    Read case study
                  </span>
                </Link>

                <div className='-m-[0.5px] flex h-16 items-center justify-center border md:h-22'>
                  <Logo name='slack' ariaLabel='Slack' className='max-h-12 w-3/4 max-w-20 dark:hidden' />
                  <Logo name='slack-darkmode' ariaLabel='Slack' className='hidden max-h-12 w-3/4 max-w-20 dark:block' />
                </div>

                <Link
                  to='/case-studies/barstool-sports'
                  prefetch='intent'
                  className='group relative -m-[0.5px] flex h-16 items-center justify-center border transition hover:bg-gray-100/50 md:h-22 dark:hover:bg-black'
                >
                  <Logo name='barstool' ariaLabel='Barstool Sports' className='max-h-12 w-3/4 max-w-20 dark:hidden' />
                  <Logo
                    name='barstool-darkmode'
                    ariaLabel='Barstool Sports'
                    className='hidden max-h-12 w-3/4 max-w-20 dark:block'
                  />
                  <span className='absolute bottom-sm text-secondary opacity-0 transition group-hover:opacity-100'>
                    Read case study
                  </span>
                </Link>

                <Link
                  to='/case-studies/community'
                  prefetch='intent'
                  className='group relative -m-[0.5px] flex h-16 items-center justify-center border transition hover:bg-gray-100/50 md:h-22 dark:hover:bg-black'
                >
                  <Logo name='community' ariaLabel='Community' className='max-h-12 w-3/4 max-w-20 dark:hidden' />
                  <Logo
                    name='community-darkmode'
                    ariaLabel='Community'
                    className='hidden max-h-12 w-3/4 max-w-20 dark:block'
                  />
                  <span className='absolute bottom-sm text-secondary opacity-0 transition group-hover:opacity-100'>
                    Read case study
                  </span>
                </Link>
              </div>

              <blockquote className='mb-1'>
                When you buy PlanetScale, you’re getting the technology and database expertise that ran and scaled
                YouTube, the internet’s #2 site, and the team that scaled GitHub to over 100M users globally.
              </blockquote>
              <p className='text-secondary'>– Todd Berman @Attentive</p>
            </div>

            <div className='flex flex-col gap-y-3'>
              <hr className='dot-fill my-3' />

              <div>
                <h2 id='performance'>
                  <Link to='#performance'>Performance</Link>
                </h2>

                <p>
                  <Link prefetch='intent' to='/metal'>
                    PlanetScale Metal
                  </Link>{' '}
                  allows you to run your database on the fastest servers available in the cloud. Our blazing fast NVMe
                  drives unlock unlimited IOPS and drastically lower latencies compared to other cloud database
                  providers like Amazon Aurora and GCP Cloud SQL.
                </p>

                <figure>
                  <picture>
                    <source
                      srcSet={insights}
                      media='(prefers-color-scheme: light), (prefers-color-scheme: no-preference)'
                    />
                    <source srcSet={insightsDarkmode} media='(prefers-color-scheme: dark)' />
                    <img
                      alt='p95 graph dropping from 45 ms to between 5 and 10 ms'
                      loading='lazy'
                      height={1724}
                      width={3337}
                      src={insights}
                    />
                  </picture>
                  <figcaption>
                    The graph above displays the p50, p95, and p99 change after moving a database to Metal. See more
                    benchmarks on our{' '}
                    <Link prefetch='intent' to='/metal/benchmarks'>
                      Benchmarking page
                    </Link>
                    .
                  </figcaption>
                  <br />
                  <blockquote className='mb-1'>
                    We are very happy with our decision to migrate to PlanetScale Metal which enabled us to achieve the
                    rare outcome of improvements in performance, cost, and reliability – a win for our customers and our
                    business.
                  </blockquote>
                  <p className='text-secondary'>– Aaron Young @ Cash App</p>
                </figure>
              </div>

              <div>
                <h2 id='vitess'>
                  <Link to='#vitess'>Vitess</Link>
                </h2>

                <p>
                  <Link prefetch='intent' to='/vitess'>
                    Vitess
                  </Link>{' '}
                  allows MySQL databases to{' '}
                  <Link prefetch='intent' to='/docs/sharding/sharding-quickstart'>
                    scale horizontally through explicit sharding
                  </Link>{' '}
                  &mdash; enabling a shared nothing architecture distributing data across thousands of nodes, all routed
                  through a single database connection.
                </p>

                <p>
                  Vitess was developed at YouTube by the founders of PlanetScale to scale their main MySQL database to
                  petabytes of data on 70,000 nodes across 20 data centers. Now maintained and managed by PlanetScale,
                  Vitess powers the databases of some of the web’s largest properties: Slack, HubSpot, Blizzard, Etsy,
                  GitHub, Block, Bloomberg, Yelp, and more.
                </p>

                <div className='mx-auto mb-3 text-center'>
                  <VitessDiagram />
                </div>

                <blockquote className='mb-1'>
                  Our migration to Vitess is more than just a technological upgrade; it’s a strategic move to
                  future-proof our database architecture for the next decade and beyond.
                </blockquote>
                <p className='text-secondary'>– Ryan Sherlock @Intercom</p>
              </div>

              <hr className='dot-fill my-3' />

              <div>
                <h2 id='uptime'>
                  <Link to='#uptime'>Uptime</Link>
                </h2>

                <p>
                  Ensuring your database is always running and your data is always safe is our number one priority.
                  Nothing comes before uptime and reliability. Our{' '}
                  <Link prefetch='intent' to='/legal/planetscale-service-level-agreement'>
                    SLA commitment
                  </Link>{' '}
                  is 99.999% for multi-region deployments and 99.99% for single-region deployments.
                </p>

                <p>
                  PlanetScale’s platform far exceeds the reliability of database services like Amazon Aurora/RDS and
                  Google Cloud SQL with superior architecture and by making all database operations online.
                </p>
                <ul className='my-0 mb-3 ml-2 space-y-1'>
                  <li>
                    <Link prefetch='intent' to='/docs/concepts/nonblocking-schema-changes'>
                      Deploy schema changes fully online
                    </Link>
                  </li>
                  <li>
                    <Link prefetch='intent' to='/docs/concepts/deploy-requests#revert-a-schema-change'>
                      Revertable schema changes (with zero data loss)
                    </Link>
                  </li>
                  <li>
                    <Link prefetch='intent' to='/blog/introducing-global-replica-credentials'>
                      Directing traffic to new read-only replicas
                    </Link>
                  </li>
                  <li>Online MySQL and Vitess version updates</li>
                  <li>
                    <Link prefetch='intent' to='/docs/concepts/cluster-sizing#upsizing-and-downsizing-clusters'>
                      Online cluster resizing and resharding
                    </Link>
                  </li>
                </ul>

                <p>
                  You can check out our track record on our{' '}
                  <a href='https://www.planetscalestatus.com/' rel='nofollow noopener noreferrer' target='_blank'>
                    status page
                  </a>
                  .
                </p>
              </div>

              <hr className='dot-fill my-3' />

              <div>
                <h2 id='cost'>
                  <Link to='#cost'>Cost</Link>
                </h2>

                <p>
                  At PlanetScale we believe cost is a unit of scale. Our product is less expensive than RDS MySQL and
                  Aurora for around 85% of the workloads that customers have migrated to Metal. Getting a custom quote
                  is easy:{' '}
                  <Link prefetch='intent' to='/contact'>
                    reach out to us
                  </Link>
                  . No long sales process or annoying pitches &mdash; you can probably tell from our website that the
                  tech speaks for itself.
                </p>

                <ul className='my-0 mb-3 ml-2 space-y-1'>
                  <li>
                    No matter the size of your workload, PlanetScale Metal has the best price to performance ratio of
                    any database service.
                  </li>
                  <li>
                    Bring your own cloud and commitment discounts with{' '}
                    <Link prefetch='intent' to='/docs/enterprise/managed'>
                      PlanetScale Managed
                    </Link>
                  </li>
                  <li>
                    Purchase through the{' '}
                    <a
                      href='https://aws.amazon.com/marketplace/pp/prodview-luy3krhkpjne4'
                      rel='nofollow noopener noreferrer'
                      target='_blank'
                    >
                      AWS Marketplace
                    </a>{' '}
                    or the{' '}
                    <a
                      href='https://console.cloud.google.com/marketplace/product/planetscale-public/planetscale-database'
                      rel='nofollow noopener noreferrer'
                      target='_blank'
                    >
                      GCP Marketplace
                    </a>{' '}
                  </li>
                  <li>
                    Customers running Metal on PlanetScale Managed can{' '}
                    <Link
                      prefetch='intent'
                      to='/blog/planetscale-metal-theres-no-replacement-for-displacement#this-performance-comes-cheap'
                    >
                      realize additional savings
                    </Link>{' '}
                    through Reserved Instances or Savings Plans &mdash; discounts otherwise not available on traditional
                    EBS volumes.
                  </li>
                </ul>
              </div>

              <hr className='dot-fill my-3' />

              <div>
                <h2 id='security'>
                  <Link to='#security'>Security</Link>
                </h2>

                <p>
                  PlanetScale is trusted by some of the world’s largest brands. Our core infrastructure was built to
                  comply with high standards of security, compliance, and privacy.
                </p>

                <ul className='my-0 mb-3 ml-2 space-y-1'>
                  <li>SOC 2 Type 2+ HIPAA compliance</li>
                  <li>PCI DSS 4.0 compliance as a Level 1 Service Provider</li>
                  <li>HIPAA Business Associate Agreements available on all plans</li>
                </ul>

                <p>
                  Learn more about our security and compliance practices in the{' '}
                  <Link prefetch='intent' to='/docs/concepts/security'>
                    security documentation
                  </Link>
                  .
                </p>

                <blockquote className='mb-1'>
                  We have very strict regulatory requirements that can feel painful to the average engineer, however
                  PlanetScale was a strong partner in grinding through our asks, leaving us in a place where everyone
                  was happy.
                </blockquote>
                <p className='text-secondary'>– Aaron Young @Cash App</p>

                <p>
                  Visit our{' '}
                  <a href='https://trust.planetscale.com' rel='nofollow noopener noreferrer' target='_blank'>
                    Trust Center
                  </a>{' '}
                  to request the latest copy of our SOC 2 Type 2 report, PCI DSS Attestation of Compliance, and more.
                </p>
              </div>

              <hr className='dot-fill my-3' />

              <div>
                <h2 id='platform'>
                  <Link to='#platform'>Features</Link>
                </h2>

                <p>
                  PlanetScale is an opinionated database platform built by the infrastructure teams behind Facebook,
                  GitHub, Twitter, Slack, YouTube,{' '}
                  <Link prefetch='intent' to='/about'>
                    and more
                  </Link>
                  . All of our features combine to provide an end-to-end database management platform that prevents
                  human error and provides full insight into query performance with actionable recommendations to make
                  your database faster.
                </p>

                <ul className='my-0 mb-3 ml-2 space-y-1'>
                  <li>Branching and deploy requests for zero downtime schema changes that your team can review.</li>
                  <li>
                    Store your{' '}
                    <Link prefetch='intent' to='/docs/vectors'>
                      vector data
                    </Link>{' '}
                    alongside your application’s relational MySQL data with PlanetScale vector support.
                  </li>
                  <li>
                    <Link prefetch='intent' to='/blog/behind-the-scenes-how-schema-reverts-work'>
                      Roll back
                    </Link>{' '}
                    bad schema changes with no downtime and no data loss.
                  </li>
                  <li>
                    Full database observability{' '}
                    <Link prefetch='intent' to='/docs/concepts/query-insights'>
                      with Insights
                    </Link>{' '}
                    to give you a detailed overview of cluster health.
                  </li>
                  <li>
                    Automate the horizontal scaling of your database with our{' '}
                    <Link prefetch='intent' to='/docs/sharding/sharding-quickstart'>
                      explicit sharding workflows
                    </Link>
                    .
                  </li>
                  <li>Utilize our Global Edge Network to automatically route query traffic to local nodes.</li>
                  <li>
                    Integrations with Fivetran, Airbyte, Hightouch, Datadog, Vantage, Debezium,{' '}
                    <Link prefetch='intent' to='/docs/concepts/planetscale-connect'>
                      and more
                    </Link>
                    .
                  </li>
                  <li>
                    All of this is backed by our{' '}
                    <Link prefetch='intent' to='/docs/support'>
                      best-in-class support
                    </Link>
                    .
                  </li>
                </ul>
              </div>
              <blockquote className='mb-1'>
                Databases are hard. We would rather PlanetScale manage them. We wanted the support PlanetScale offers
                because they are the experts in the field. We’ve seen this come to fruition in our relationship.
              </blockquote>
              <p className='text-secondary'>– Chris Karper @ MyFitnessPal</p>
            </div>
          </div>
        </section>
      </main>

      <SiteFooter />
    </>
  )
}
